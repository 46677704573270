<template>
  <div>
    <busy :visible="loading" />
    <div
      v-if="!loading && backgroundTasks.length === 0"
      class="text-center mt-2"
    >
      <p>
        <i class="far fa-info-circle fa-3x text-primary"></i>
      </p>
      <p>No background tasks found.</p>
    </div>
    <div v-else>
      <table class="table table-hover">
        <tbody>
        <tr v-for="(t, index) in backgroundTasks" :key="t.id">
          <td :style="index < 1 ? 'border-top: 0px;' : ''" style="width: 1%; white-space: nowrap">
            <i
                :class="[
                    'fad',
                    'text-primary',
                    getIcon(t),
                  ]"
            ></i>
          </td>
          <td :style="index < 1 ? 'border-top: 0px;' : ''" style="width: 1%; white-space: nowrap">
            {{ t.created_at | relative }}
          </td>
          <td :style="index < 1 ? 'border-top: 0px;' : ''">
            {{ t.title }}
          </td>
          <td :style="index < 1 ? 'border-top: 0px;' : ''" class="text-end">
            <span class="badge text-capitalize" :class="getStatusClass(t)">
              {{ t.status }}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <!--  -->
      <div class="row" v-if="loading">
        <div class="col">
          <div class="row">
            <div class="col my-auto">
              <button
                  class="btn btn-sm btn-light"
                  @click="fetchBackgroundTasks(pagination.first_page_url)"
                  :disabled="!pagination.first_page_url"
              >
                First Page
              </button>

              <button
                  class="btn btn-sm btn-light ms-3"
                  @click="fetchBackgroundTasks(pagination.prev_page_url)"
                  :disabled="!pagination.prev_page_url"
              >
                Previous
              </button>
            </div>
            <div class="col my-auto text-center">
              <p class="mb-0">
                Page {{ pagination.current_page }} of
                {{ pagination.last_page }}
              </p>
            </div>
            <div class="col my-auto text-end">
              <button
                  class="btn btn-sm btn-light cursor-pointer me-3"
                  @click="fetchBackgroundTasks(pagination.next_page_url)"
                  :disabled="!pagination.next_page_url"
              >
                Next
              </button>

              <button
                  class="btn btn-sm btn-light cursor-pointer"
                  @click="fetchBackgroundTasks(pagination.last_page_url)"
                  :disabled="!pagination.last_page_url"
              >
                Last Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      loading: true,
      saving: false,
      backgroundTasks: [],
      pagination: {},
    };
  },
  methods: {
    fetchBackgroundTasks(paginationUrl) {
      const url = paginationUrl ?? process.env.VUE_APP_API_URL + "/background-tasks"
      this.$axios
          .get(url)
          .then(({ data }) => {
            this.pagination = data;
            this.backgroundTasks = data.data;
            this.loading = false;
          });
    },
    getIcon(task) {
      if (task.finished) {
        return task.status === 'failed' ? 'fa-times-circle text-danger' : 'fa-check-circle text-success'
      } else {
        return 'fa-spinner-third fa-spin text-primary'
      }
    },
    getStatusClass(task) {
      if (task.finished) {
        return task.status === 'failed' ? 'bg-danger' : 'bg-success'
      } else {
        return 'bg-primary'
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  filters: {
    relative(date) {
      return moment(date).local().locale("en-short").fromNow();
    },
  },
  created() {
    this.fetchBackgroundTasks();
  },
};
</script>

<style>
</style>
