<template>
  <div class="container-fluid">
    <h4 class="fw-bold text-primary">Notifications</h4>
    <notifications-list class="mb-4"/>
    <h4 class="fw-bold text-primary">Background Tasks</h4>
    <background-task-list class="mb-4"/>
  </div>
</template>

<script>
import NotificationsList from "@/views/areas/notifications/index-partials/NotificationsList";
import BackgroundTaskList from "@/views/areas/notifications/index-partials/BackgroundTaskList";
export default {
  components: {BackgroundTaskList, NotificationsList},
  props: [],
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {

  },
  filters: {

  },
  created() {
  },
};
</script>

<style>
</style>
