<template>
  <div>
    <busy :visible="loading" />
    <div v-if="!loading && notifications.length === 0" class="text-center mt-2">
      <p>
        <i class="far fa-info-circle fa-3x text-primary"></i>
      </p>
      <p>No notifications found.</p>
    </div>
    <div v-else>
      <table class="table table-hover">
        <tbody>
        <tr v-for="(n, index) in notifications" :key="n.id">
          <td :style="index < 1 ? 'border-top: 0px;' : ''" style="width: 1%; white-space: nowrap">
            <i
                :class="[
                    'fad',
                    'text-primary',
                    n.icon,
                  ]"
            ></i>
          </td>
          <td :style="index < 1 ? 'border-top: 0px;' : ''" style="width: 1%; white-space: nowrap">
            {{ n.created_at | relative }}
          </td>
          <td :style="index < 1 ? 'border-top: 0px;' : ''"><span v-if="n.isCC" class="badge bg-secondary">CC</span> {{ n.body }}</td>
          <td :style="index < 1 ? 'border-top: 0px;' : ''" class="text-end">
            <router-link
                :to="n.action_url"
                class="btn btn-sm btn-outline-primary"
                v-if="n.action_text"
            >
              {{ n.action_text }}
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <!--  -->
      <div class="row" v-if="!loading">
        <div class="col">
          <div class="row">
            <div class="col my-auto">
              <button
                  class="btn btn-sm btn-light"
                  @click="fetchNotifications(pagination.first_page_url)"
                  :disabled="!pagination.first_page_url"
              >
                First Page
              </button>

              <button
                  class="btn btn-sm btn-light ms-3"
                  @click="fetchNotifications(pagination.prev_page_url)"
                  :disabled="!pagination.prev_page_url"
              >
                Previous
              </button>
            </div>
            <div class="col my-auto text-center">
              <p class="mb-0">
                Page {{ pagination.current_page }} of
                {{ pagination.last_page }}
              </p>
            </div>
            <div class="col my-auto text-end">
              <button
                  class="btn btn-sm btn-light cursor-pointer me-3"
                  @click="fetchNotifications(pagination.next_page_url)"
                  :disabled="!pagination.next_page_url"
              >
                Next
              </button>

              <button
                  class="btn btn-sm btn-light cursor-pointer"
                  @click="fetchNotifications(pagination.last_page_url)"
                  :disabled="!pagination.last_page_url"
              >
                Last Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      loading: true,
      saving: false,
      notifications: [],
      pagination: {},
    };
  },
  methods: {
    fetchNotifications(paginationUrl) {
      const url = paginationUrl ?? process.env.VUE_APP_API_URL + "/notifications/paged"
      this.$axios
          .get(url)
          .then(({ data }) => {
            this.pagination = data;
            this.notifications = data.data;
            this.loading = false;
          });
    },
    markNotificationsAsRead() {
      if (!this.hasUnreadNotifications) {
        return;
      }

      this.$axios.put(process.env.VUE_APP_API_URL + "/notifications/all-read", {
        notifications: _.map(this.notifications, "id"),
      });

      this.$EventBus.$emit("notificationsRead");
    },
  },
  computed: {
    hasNotifications: function () {
      return this.notifications && this.notifications.length > 0;
    },
    unreadNotificationsCount: function () {
      if (this.notifications) {
        return _.filter(this.notifications, function (notification) {
          return !notification.read;
        }).length;
      }

      return 0;
    },
    hasUnreadNotifications: function () {
      return this.unreadNotificationsCount > 0;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  filters: {
    relative(date) {
      return moment(date).local().locale("en-short").fromNow();
    },
  },
  created() {
    this.fetchNotifications();
  },
};
</script>

<style>
</style>
